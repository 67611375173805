
import { DashboardStatsState } from "@/store/modules/stats/stats.types";
import VueApexCharts from "vue-apexcharts";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Stats = namespace("Stats");

@Component({
  components: {
    VueApexCharts,
  },
})
export default class InputCategoriesChart extends Vue {
  @Stats.State("dashboardStats")
  public dashboardStats!: DashboardStatsState;

  get options(): unknown {
    return {
      chart: {
        id: "vuechart-example",
      },
      legend: {
        position: "bottom",
      },
      labels: this.dashboardStats.inputs.stats.labels,
      colors: [
        "#C2185B",
        "#6A1B9A",
        "#00C853",
        "#E65100",
        "#4527A0",
        "#00B0FF",
      ],
    };
  }

  get series(): number[] {
    return this.dashboardStats.inputs.stats.data;
  }
}
